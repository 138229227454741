import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { CITIZEN_CORNER } from '../../components/constant'
const CitizenCorner = () => {
  return (
    <div>
      <Layout>
        <SEO title="Citizen Corner" />
        <PageStructure
          title="Citizen Corner"
          desc="TESTIG"
          mode={CITIZEN_CORNER}
        />
      </Layout>
    </div>
  )
}

export default CitizenCorner
